<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/finance_2/banner.png" alt="" />
    </div>
    <div class="container" id="rzdk">
      <div class="box">
        <div class="title">融资/贷款服务</div>
        <div class="list">
          <div class="item item_first">
            <div class="img">
              <img src="../../assets/img/finance_2/cover0.png" alt="" />
            </div>
            <div class="mask">
              <span class="title">民生惠信用贷</span>
              <span @click="subInfo('ms')">立即申请></span>
              <div class="loan_box">
                <span>最高可贷</span>
                <span class="title">300万</span>
                <span>年化利率</span>
                <span class="title">3.65%<span>起</span></span>
              </div>
            </div>
          </div>
          <!-- <div class="item">
            <div class="img">
              <img src="../../assets/img/finance_2/cover1.png" alt="" />
            </div>
            <div class="mask">
              <span class="title">金虎e贷</span>
              <span @click="subInfo">立即申请></span>
              <div class="loan_box">
                <span>最高可贷</span>
                <span class="title">150万</span>
                <span>年化利率</span>
                <span class="title">3.45%<span>起</span></span>
              </div>
            </div>
          </div> -->
          <div class="item">
            <div class="expect">敬请期待</div>
            <div class="img">
              <img src="../../assets/img/finance_2/cover2.png" alt="" />
            </div>
            <div class="mask">
              <span class="title">烟商贷</span>
              <span @click="subInfo">立即申请></span>
              <div class="loan_box">
                <span>最高可贷</span>
                <span class="title">100万</span>
                <span>年化利率</span>
                <span class="title">3.45%<span>起</span></span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="expect">敬请期待</div>
            <div class="img">
              <img src="../../assets/img/finance_2/cover3.png" alt="" />
            </div>
            <div class="mask">
              <span class="title">同城e贷</span>
              <span @click="subInfo">立即申请></span>
              <div class="loan_box">
                <span>最高可贷</span>
                <span class="title">50万</span>
                <span>年化利率</span>
                <span class="title">3.45%<span>起</span></span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="expect">敬请期待</div>
            <div class="img">
              <img src="../../assets/img/finance_2/cover4.png" alt="" />
            </div>
            <div class="mask">
              <span class="title">农商e贷</span>
              <span @click="subInfo">立即申请></span>
              <div class="loan_box">
                <span>最高可贷</span>
                <span class="title">300万</span>
                <span>年化利率</span>
                <span class="title">3.45%<span>起</span></span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="expect">敬请期待</div>
            <div class="img">
              <img src="../../assets/img/finance_2/cover5.png" alt="" />
            </div>
            <div class="mask">
              <span class="title">商户e贷</span>
              <span @click="subInfo">立即申请></span>
              <div class="loan_box">
                <span>最高可贷</span>
                <span class="title">200万</span>
                <span>年化利率</span>
                <span class="title">3.45%<span>起</span></span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="expect">敬请期待</div>
            <div class="img">
              <img src="../../assets/img/finance_2/cover6.png" alt="" />
            </div>
            <div class="mask">
              <span class="title">快付贷</span>
              <span @click="subInfo">立即申请></span>
              <div class="loan_box">
                <span>最高可贷</span>
                <span class="title">300万</span>
                <span>年化利率</span>
                <span class="title">3.45%<span>起</span></span>
              </div>
            </div>
          </div>
          <i></i>
          <i></i>
        </div>
      </div>
    </div>

    <div class="container" id="hzjg">
      <div class="box">
        <div class="title">合作机构</div>
        <div class="img_list">
          <div class="img_item">
            <img src="../../assets/img/finance_2/hzjg1.png" alt="" />
          </div>
          <div class="img_item">
            <img src="../../assets/img/finance_2/hzjg2.png" alt="" />
          </div>
          <div class="img_item">
            <img src="../../assets/img/finance_2/hzjg3.png" alt="" />
          </div>
          <div class="img_item">
            <img src="../../assets/img/finance_2/hzjg4.png" alt="" />
          </div>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

    <div class="container" id="kjbx">
      <div class="box">
        <div class="title">快捷服务</div>
        <div class="icon_list">
          <div class="icon_item">
            <a
              href="https://www.yhwdt.cn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="../../assets/img/finance_2/kjfw1.png" alt="" />
              <span>网点查询</span>
            </a>
          </div>
          <div class="icon_item">
            <a
              href="https://www.qianbo.com.cn/Tool/Saving-Calculator.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="../../assets/img/finance_2/kjfw2.png" alt="" />
              <span>理财计算器</span>
            </a>
          </div>
          <div class="icon_item">
            <a
              href="https://www.ant2.cn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="../../assets/img/finance_2/kjfw3.png" alt="" />
              <span>贷款计算器</span>
            </a>
          </div>
          <div class="icon_item" @click="outsideClick(4)">
            <img src="../../assets/img/finance_2/kjfw4.png" alt="" />
            <span>企业开户</span>
          </div>
          <div class="icon_item" @click="outsideClick(4)">
            <img src="../../assets/img/finance_2/kjfw5.png" alt="" />
            <span>信用卡办理</span>
          </div>
          <div class="icon_item" @click="outsideClick(4)">
            <img src="../../assets/img/finance_2/kjfw6.png" alt="" />
            <span>储蓄卡办理</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    // 外链
    outsideClick(num) {
      if (num == 1) {
        window.location.href = "https://www.yhwdt.cn/";
      }
      if (num == 2) {
        window.location.href =
          "https://www.qianbo.com.cn/Tool/Saving-Calculator.html";
      }
      if (num == 3) {
        window.location.href = "https://www.ant2.cn/";
      }
      if (num == 4) {
        this.$router.push({
          path: "/info",
          query: { org: "jr" },
        });
      }
    },
    subInfo(flag) {
      if (flag == "ms") {
        this.$router.push({
          path: "/finance_2/loan_1",
        });
      } else {
        window.open("http://218.26.36.12:33599/applyInfo/index");
      }

      // this.$router.push({
      //   path: "/info",
      //   query: { org: "jr" },
      // });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  padding: 40px;
}

.container:nth-child(2n-1) {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}

.item {
  position: relative;
  width: 32%;
  height: 184px;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}

.list i {
  width: 32%;
}

.img {
  width: 100%;
  height: 100%;
}

.img img {
  display: block;
  width: 100%;
  height: 100%;
}

.mask {
  position: absolute;
  top: 0;
  right: 0;
  width: 116px;
  height: 184px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.loan_box {
  margin-top: 10px;
}

.mask span {
  display: block;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  margin-top: 4px;
  /* margin-bottom: 10px; */
}

.mask span.title {
  display: block;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.mask span.title span {
  font-weight: normal;
  display: inline;
}

/* .mask span:last-child {
  font-weight: 400;
  font-size: 12px;
  margin-top: 10px;
} */

/* .item:hover .mask {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: #055afe;
} */
.item_first:hover .mask {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: #055afe;
}

.img_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}

.img_list span {
  display: block;
  width: 23%;
  margin: 10px;
}

.img_item {
  width: 23%;
  /* width: 306px;
  height: 100px; */
  margin: 10px;
  padding: 20px;
  background: #fff;
}

.img_item img {
  display: block;
  width: 100%;
  /* width: 100%;
  height: 100%; */
}

.icon_list {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.icon_item {
  /* width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center; */
  margin: 30px 60px;
}

.icon_item span {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.icon_item a {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.expect {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: #F2F2F2;
  text-align: center;
  line-height: 184px;
  font-size: 24px;
  z-index: 10;
}
</style>
